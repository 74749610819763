.hero {
    position: relative;    

    // &:after, &:before {
    //     background-color: #fff;
 
    //     transform: scale(0);
    //     content: '';
    //     height: 1px;
    //     width: 100vw;
    //     position: absolute;
    //     transition: 3s ease;
    // }
    // &:before {
    //     top: 50px;
      
    //     transform-origin: left;

    //     @media (min-width: 768px ) {
    //         top: 70px;
    //     }
    // }
    // &:after {
    //     bottom: 50px;
    //     transform-origin: right;

    //     @media (min-width: 768px ) {
    //         bottom: 70px;
    //     }
    // }

    &__ttl {
        transition: 3s ease;
        opacity: 0;
    }

    &.is-animate {
        &:before, &:after {
            transform: scale(1);
        }

        .hero {
            &__ttl {
                opacity: 1;
            }
        }

        .flowers {

            opacity: 1;
            // animation: rotation 5s normal;
        }
    }

    .title {
        mix-blend-mode: difference;
    }

    .flowers {
        opacity: 1;
        transform: translateY(10px);
    }
}

.section:has(.hero) {
    padding: 0;
}

@keyframes rotation {
    0% { 
        transform:rotate(0deg); 
    } 
    100% { 
        transform:rotate(360deg); 
        transform:rotate(calc(360deg * 1)); 
    } 
}