:root {
    background-color: #000;
 

 
}
body {

}

#root {
    overflow: hidden;
}

.section {
    padding: 0 20px;
    @media (min-width: 768px ) {
        padding: 0 5vw;
    }
}

.wrapper {
    padding: 49px 30px;
   
    @media (min-width: 768px ) {
        max-width: 1512px;
        margin: auto;
        padding: 5vw;
    }

    
}

.txt {
    font-size: 12px;

    @media (min-width: 768px ) {
        font-size: 16px;
    }
}


// a {
//     @media (min-width: 768px ) {
//         cursor: none;
//     }
// }