 @-webkit-keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-1 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-1 {
    -webkit-animation: animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
            animation: animate-svg-fill-1 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.2s both;
  }
  
  @-webkit-keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-2 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-2 {
    -webkit-animation: animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both;
            animation: animate-svg-fill-2 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.3s both;
  }
  
  @-webkit-keyframes animate-svg-fill-3 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-3 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-3 {
    -webkit-animation: animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
            animation: animate-svg-fill-3 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.4s both;
  }
  
  @-webkit-keyframes animate-svg-fill-4 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-4 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-4 {
    -webkit-animation: animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
            animation: animate-svg-fill-4 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.5s both;
  }
  
  @-webkit-keyframes animate-svg-fill-5 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-5 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-5 {
    -webkit-animation: animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
            animation: animate-svg-fill-5 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.6s both;
  }
  
  @-webkit-keyframes animate-svg-fill-6 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-6 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-6 {
    -webkit-animation: animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s both;
            animation: animate-svg-fill-6 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.7s both;
  }
  
  @-webkit-keyframes animate-svg-fill-7 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-7 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-7 {
    -webkit-animation: animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
            animation: animate-svg-fill-7 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 0.8s both;
  }
  
  @-webkit-keyframes animate-svg-fill-8 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-8 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-8 {
    -webkit-animation: animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
            animation: animate-svg-fill-8 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.5s both;
  }
  
  @-webkit-keyframes animate-svg-fill-9 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-9 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-9 {
    -webkit-animation: animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
            animation: animate-svg-fill-9 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.6s both;
  }
  
  @-webkit-keyframes animate-svg-fill-10 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-10 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-10 {
    -webkit-animation: animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
            animation: animate-svg-fill-10 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.7000000000000002s both;
  }
  
  @-webkit-keyframes animate-svg-fill-11 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-11 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-11 {
    -webkit-animation: animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
            animation: animate-svg-fill-11 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.8s both;
  }
  
  @-webkit-keyframes animate-svg-fill-12 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-12 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-12 {
    -webkit-animation: animate-svg-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
            animation: animate-svg-fill-12 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 1.9000000000000001s both;
  }
  
  @-webkit-keyframes animate-svg-fill-13 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-13 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-13 {
    -webkit-animation: animate-svg-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
            animation: animate-svg-fill-13 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2s both;
  }
  
  @-webkit-keyframes animate-svg-fill-14 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-14 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-14 {
    -webkit-animation: animate-svg-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
            animation: animate-svg-fill-14 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.1s both;
  }
  
  @-webkit-keyframes animate-svg-fill-15 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-15 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-15 {
    -webkit-animation: animate-svg-fill-15 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
            animation: animate-svg-fill-15 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.2s both;
  }
  
  @-webkit-keyframes animate-svg-fill-16 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-16 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-16 {
    -webkit-animation: animate-svg-fill-16 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
            animation: animate-svg-fill-16 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.3s both;
  }
  
  @-webkit-keyframes animate-svg-fill-17 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-17 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-17 {
    -webkit-animation: animate-svg-fill-17 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s both;
            animation: animate-svg-fill-17 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.4000000000000004s both;
  }
  
  @-webkit-keyframes animate-svg-fill-18 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-18 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-18 {
    -webkit-animation: animate-svg-fill-18 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
            animation: animate-svg-fill-18 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.5s both;
  }
  
  @-webkit-keyframes animate-svg-fill-19 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-19 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-19 {
    -webkit-animation: animate-svg-fill-19 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s both;
            animation: animate-svg-fill-19 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.6s both;
  }
  
  @-webkit-keyframes animate-svg-fill-20 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-20 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-20 {
    -webkit-animation: animate-svg-fill-20 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s both;
            animation: animate-svg-fill-20 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.7s both;
  }
  
  @-webkit-keyframes animate-svg-fill-21 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-21 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-21 {
    -webkit-animation: animate-svg-fill-21 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s both;
            animation: animate-svg-fill-21 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.8s both;
  }
  
  @-webkit-keyframes animate-svg-fill-22 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-22 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-22 {
    -webkit-animation: animate-svg-fill-22 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s both;
            animation: animate-svg-fill-22 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 2.9000000000000004s both;
  }
  
  @-webkit-keyframes animate-svg-fill-23 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-23 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-23 {
    -webkit-animation: animate-svg-fill-23 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s both;
            animation: animate-svg-fill-23 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3s both;
  }
  
  @-webkit-keyframes animate-svg-fill-24 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-24 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-24 {
    -webkit-animation: animate-svg-fill-24 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1000000000000005s both;
            animation: animate-svg-fill-24 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.1000000000000005s both;
  }
  
  @-webkit-keyframes animate-svg-fill-25 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-25 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-25 {
    -webkit-animation: animate-svg-fill-25 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s both;
            animation: animate-svg-fill-25 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.2s both;
  }
  
  @-webkit-keyframes animate-svg-fill-26 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-26 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-26 {
    -webkit-animation: animate-svg-fill-26 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s both;
            animation: animate-svg-fill-26 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.3s both;
  }
  
  @-webkit-keyframes animate-svg-fill-27 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-27 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-27 {
    -webkit-animation: animate-svg-fill-27 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s both;
            animation: animate-svg-fill-27 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.4000000000000004s both;
  }
  
  @-webkit-keyframes animate-svg-fill-28 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-28 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-28 {
    -webkit-animation: animate-svg-fill-28 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s both;
            animation: animate-svg-fill-28 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.5s both;
  }
  
  @-webkit-keyframes animate-svg-fill-29 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-29 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-29 {
    -webkit-animation: animate-svg-fill-29 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6000000000000005s both;
            animation: animate-svg-fill-29 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.6000000000000005s both;
  }
  
  @-webkit-keyframes animate-svg-fill-30 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-30 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-30 {
    -webkit-animation: animate-svg-fill-30 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.7s both;
            animation: animate-svg-fill-30 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.7s both;
  }
  
  @-webkit-keyframes animate-svg-fill-31 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-31 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-31 {
    -webkit-animation: animate-svg-fill-31 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s both;
            animation: animate-svg-fill-31 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.8s both;
  }
  
  @-webkit-keyframes animate-svg-fill-32 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  @keyframes animate-svg-fill-32 {
    0% {
      fill: transparent;
    }
  
    100% {
      fill: rgb(29, 29, 27);
    }
  }
  
  .svg-elem-32 {
    -webkit-animation: animate-svg-fill-32 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.9000000000000004s both;
            animation: animate-svg-fill-32 0.7s cubic-bezier(0.47, 0, 0.745, 0.715) 3.9000000000000004s both;
  }
  