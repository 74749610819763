.projectCard {
   
    &:nth-child(1) {
        @media (max-width: 768px) {
            margin-left: 20px;
        }
    }
    &:nth-child(2) {
        @media (max-width: 768px) {
            margin-right: 20px;
        }
    }
    & + & {
        margin-top: 200px;
        @media (max-width: 768px) {
            margin-top: 50px;
        }
    }
}