.aboutSect {
    .line {
        position: absolute;
        background-color: #000;
        --scale: 1;
    
        &:nth-child(1) {
            width: 100%;
            top: 0;
            left: 0px;
            height: 1px;
            transform-origin: left;
            transform: scaleX(var(--scale));

    
        }
        &:nth-child(2) {
            width: 100%;
            height: 1px;
            bottom: 0;
            left: 0;
            transform-origin: right;
            transform: scaleX(var(--scale));
        }
        &:nth-child(3) {
            height: 100%;
            width: 1px;
            left: 0;
            top: 0;
            transform-origin: top;
            transform: scaleY(var(--scale));
            
        }
        &:nth-child(4) {
            height: 100%;
            width: 1px;
            right: 0;
            top: 0;
            transform-origin: bottom;
            transform:scaleY(var(--scale));
        }
    }
    .wrapper {
 

        @media (min-width:786px) {
            min-height: 800px;
        }
    }
}