.maskText {
    position: relative;
    display: inline-block;
    width: fit-content;
  
    --text-mask: 0%;
    &__cover {
      height: fit-content;
      position: relative;
      clip-path: inset(0 calc(100% - var(--text-mask, 0%)) 0 0);
    }
  
    &__base {
      height: fit-content;
      left: 0;
      opacity: .3;
      position: absolute;
      top: 0;
    }
  
  }
  